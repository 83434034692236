import consumerApi from "api/services/consumer-api";
import { PromotedBrand } from "custom-types/Brand";
import { MenuItem } from "custom-types/MenuItem";
import logError from "utils/logError";

export type Placement = {
  brand: PromotedBrand;
  menuItems: MenuItem[];
};

/**
 * Retrieves a randomly selected brand offered by the dispensary to promote, along
 * with the menu items that should be featured.
 */
const getBrandPlacement = async (
  dispensarySlug: string,
  { menuType, strategies }: { menuType?: string; strategies?: string[] } = {},
): Promise<Placement | null> => {
  try {
    const { data = null } = await consumerApi.get(
      `api/dispensaries/v1/${dispensarySlug}/promoted_brand_placement`,
      {
        params: { menuType: menuType?.toLowerCase(), strategies },
      },
    );

    return data;
  } catch (e) {
    // A lot of dispensaries don't use this, and when that happens
    // a 404 is returned and is not useful to log.
    if (e.statusCode !== 404) {
      logError(e.message, {
        functionName: "getBrandPlacement",
        service: "consumer",
        statusCode: e.statusCode,
      });
    }

    return null;
  }
};
export default getBrandPlacement;
