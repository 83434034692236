import { useSelector } from "react-redux";

import {
  getIsOrderingAllowed,
  getIsThirdPartyOrderingProhibited,
} from "redux/selectors/complianceRules";
import { getCurrentDispensary } from "redux/selectors/dispensary";

import NonCompliantMenu from "../NonCompliantMenu";

const MenuNotAvailable: React.FC = () => {
  const thirdPartyOrderingProhibited = useSelector(
    getIsThirdPartyOrderingProhibited,
  );
  const { featureTier, retailType } = useSelector(getCurrentDispensary);

  const isInfoTier = featureTier === "info";
  const isDispensaryRetailType = retailType === "dispensary";

  // * Only "dispensary" types are restricted, since clinic and cbd-store types
  // * typically don't sell restricted items.
  const isLeaflyOrderingAllowed =
    useSelector(getIsOrderingAllowed) || !isDispensaryRetailType;

  return !thirdPartyOrderingProhibited &&
    !isLeaflyOrderingAllowed &&
    isDispensaryRetailType ? (
    <NonCompliantMenu
      hideCTA={isInfoTier}
      thirdPartyOrderingProhibited={thirdPartyOrderingProhibited}
      isLeaflyOrderingAllowed={isLeaflyOrderingAllowed}
    />
  ) : null;
};

export default MenuNotAvailable;
