import { useEffect, useState } from "react";
import classnames from "classnames";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";

import useDomainCountryCode from "hooks/useDomainCountryCode";
import useFilters from "hooks/useFilters";
import {
  getCurrentDispensary,
  getIsDualMenu,
} from "redux/selectors/dispensary";
import { dispensaryHref } from "utils/dispensaryUrls";
import { scrollToTop } from "utils/scrollPosition";
import { trackMenuEvent } from "utils/trackEvent";

import SearchInput from "components/SearchInput";
import MenuTypeSelection from "components/Shared/MenuTypeSelection";

type Props = {
  routeToMenu?: boolean;
  className?: string;
};

const SearchBar: React.FC<Props> = ({ routeToMenu = false, className }) => {
  const { slug: dispensarySlug, retailType } =
    useSelector(getCurrentDispensary);
  const countryCode = useDomainCountryCode();
  const isDualMenuDispensary = useSelector(getIsDualMenu);

  const { selectFilterValue, addFilter, addDispensaryFilter } = useFilters();
  const { push, query } = useRouter();

  const queryParam = selectFilterValue("q");
  const [searchValue, setSearchValue] = useState(queryParam || "");

  useEffect(() => {
    if (!queryParam && searchValue) {
      setSearchValue("");
    }
  }, [queryParam]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  const handleChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  const handleClear = () => {
    if (searchValue) {
      setSearchValue("");
    }
    if (queryParam) {
      addFilter({ q: undefined });
      scrollToTop("smooth");
    }
  };

  const pushNewQuery = (newQuery: string) => {
    const menuType = query.menu_type ? `&menu_type=${query.menu_type}` : "";
    if (routeToMenu) {
      const link = dispensaryHref({
        additionalPath: `/menu?q=${newQuery}${menuType}`,
        countryCode,
        retailType,
        slug: dispensarySlug,
      });

      push(link);
    } else {
      addFilter({ q: newQuery });
      scrollToTop("smooth");
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  const handleSubmit = (e: any) => {
    // If function was called from onKeyPress, only proceed with Enter
    if (e.key && e.key !== "Enter") {
      return;
    }

    // Remove unwanted characters from search query
    const formattedValue = String(searchValue).replace(/[^\w\s-_]/g, "");

    if (formattedValue !== queryParam) {
      pushNewQuery(formattedValue);
      trackMenuEvent("Search", formattedValue);
    }
  };

  const handleMenuChange = (value?: string) => {
    addDispensaryFilter("menu_type", value);
  };

  return (
    <>
      <div className={classnames(className)}>
        <div className="flex">
          <SearchInput
            inputProps={{
              onChange: handleChange,
              onKeyDown: handleSubmit,
              value: searchValue,
            }}
            clearButtonProps={{
              onClick: handleClear,
            }}
            searchButtonProps={{
              onClick: handleSubmit,
            }}
          />

          <MenuTypeSelection
            className="ml-sm"
            isDualMenu={isDualMenuDispensary}
            onMenuTypeChange={handleMenuChange}
            showSelectInput={true}
          />
        </div>
      </div>
    </>
  );
};

export default SearchBar;
